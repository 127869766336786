export const messages = {
  en: {
    siteTitle: "NMCS",
    siteTitleFull: "National Mediation and Conciliation Service",
    siteTitleAll1: "National Mediation",
    siteTitleAll2: "and Conciliation Service",
    ofisialWebsite: "Official website",
    license1: "All content is available under a Creative Commons license",
    license2: "Attribution 4.0 International license unless otherwise noted",
    test_mode: "The site works in test mode",
    breadcrumbs: {
      main: "Main",
      aboutUs: "About us",
      activities: "Activity areas",
      socialDialogue: "Social dialogue",
      pressCenter: "Press center",
      news: "News",
      mediaLibrary: "Media library",
      events: "Events",
      gallery: "Gallery",
      pubInMedia: "Publications in mass media",
      reports: "Reports",
      governmentCleans: "Government Cleans",
      submit_documents: "Submit Documents",
      forPublic: "For the public",
      resources: "Registries and resources",
      vacancies: "Vacancies and contests",
    },
    common: {
      share: "Share",
      tags: "Tags",
      sections: "Sections",
      consent:
        "By registering an application, I consent to the processing and use of personal data in accordance with the legislation of Ukraine",
      fieldIsRequired: "Field is required.",
    },
    galleries: {
      photoTitle: "Photo",
      videoTitle: "Video",
      allPhotoLink: "All photos",
      allVideoLink: "All videos",
    },
    menu: {
      aboutUs: {
        title: "About us",
        mission: "Mission, vision, values",
        stakeholders: "Our stakeholders",
        history: "History",
        cooperation: "International cooperation",
        departments: "Structure",
        contacts: "Contacts",
        reports: "Activity reports",
      },
      areasOfActivities: {
        title: "Areas of activities",
        collectiveDisputes: "Collective disputes (conflicts)",
        disputesConflicts: "The concept of a collective labor dispute (conflict)",
        alorithm: "Algorithm for solving a collective labor dispute (conflict)",
        trainingLaborArbitrators: "Training of labor arbitrators and independent mediators",
        prevention: "Prevention of collective labor disputes",
        socialDialogue: "Social dialogue",
        concept: "Social dialogue concept",
        legislation: "Legislation of Ukraine on social dialogue",
        principles: "Basic principles of social dialogue",
        determination: "Determination of representativeness criteria",
        peculiarities:
          "Peculiarities of submitting documents and extending the validity of certificates under martial law",
        socioEconomicCouncil: "National tripartite socio-economic council",
        foreignExperience: "Foreign experience",
      },
      regulatory: "Normative base",
      resources: {
        title: "Registries and resources",
        bulletin: "Bulletin",
        collectiveLaborDisputesRegister: "Register of collective labor disputes (conflicts)",
        partiesEntitiesRegister:
          "Register of representative subjects of parties of trade unions and employers' organizations",
        laborArbitrators: "List of labor arbitrators",
        independentMediators: "List of independent mediators",
        links: "Useful links",
      },
      forPublic: {
        title: "For the public",
        appealOfCitizens: "Appeal of citizens",
        governmentProcurement: "Government procurement",
        publicInformation: "Access to public information",
        actualVacancies: "Vacancies and contests",
        governmentCleans: "Purification of power",
        submitDocuments: "Submit documents for registration",
        antiCorruptionPolicy: "Prevention and counteraction of corruption",
      },
      pressCenter: {
        title: "Press center",
        news: "News",
        mediaLibrary: "Media library",
        announcements: "Announcements",
        pubInMedia: "Publications in mass media",
        popularTags: "Popular news tags",
      },
    },
    home: {
      announcements: "Events",
      all_announcements: "All events",
      electronicAppeal: "Electronic appeal",
      sendAppeal: "Send appeal",
      submitElectronicAppeal:
        "Submit your questions, complaints, suggestions and get the necessary information from any place where there is access to the Internet.",
      license:
        "All content is available under the Creative Commons Attribution 4.0 International license unless otherwise noted",
    },
    stakeholders: {
      allStakeholdersLink: "All stakeholders",
    },
    emptyResults: {
      defaultTitle: "Nothing was found for your request",
      noDocuments: "No documents were found for your request",
      action: "What to do with it",
      step1: "Make sure all words are spelled correctly.",
      step2: "Try other keywords.",
      step3: "Try to use more general words.",
    },
    regulatoryFramework: {
      title:
        "Get access to the complete regulatory framework - exactly the documents and laws you need",
      text:
        "We provide you with the necessary information on documents regulating the activities of state bodies and " +
        "institutions in the field of disputes and conflicts",
      link: "Go to the regulatory framework",
      newDocs: "New legislation documents",
      oftenSearch: "Often searched for",
      count: "In general",
      docs: "document",
      filters: {
        searchPlaceholder: "Search documents",
        sectionLabel: "Document section",
        sectionPlaceholder: "Select section",
        sectionOptions: {
          legislation: "Законодавство",
          activity: "НПА за напрямами діяльності",
          judicialPractice: "Судова практика",
        },
        dateLabel: "Publication date",
        datePlaceholder: "Select date",
        searchButtonText: "Search",
      },
    },
    news: {
      news: "News",
      mainNews: "Main news",
      newsByTopic: "News by topic",
      error: "Не вдалося завантажити новини. Спробуйте пізніше",
      newsSub: {
        digest: "We send you a weekly news digest",
        subscribe:
          "Sign up to receive emails with the most important news about the work of our service.",
        agreement:
          "By clicking the button, you automatically agree to the processing of personal data",
      },
      subscription: {
        placeholder: "Your email",
        subscribeButton: "Subscribe",
        emailExists: "You are already subscribed",
        error: "Failed to subscribe",
        success: "Subscribed successfully",
      },
      allNews: "All News",
      searchByName: "Search news by name",
      datePickerLabel: "Publication date",
      datePickerPlaceholder: "Select date",
      stayUpToDate: "Stay up to date with the news",
      publicationDate: "publication date",
      tags: "tags",
      foundBy: "Found by ",
      searchQuery: "search query",
      clearFilters: "Clear results",
      foundResults: "Found by search query",
      searchResults: "Search results",
      emptyResults: "No news were found for your request",
    },
    events: {
      error: "Failed to load the event. Try later",
      calendar: "Add to the calendar",
      date: "Date",
      time: "Time",
      location: "Location",
      upcoming_events: "Upcoming events",
      filter: {
        title: "Found by author",
        clear: "Clear the results",
      },
    },
    gallery: {
      allDepartments: "All departments",
      contentType: "Content type",
      contentTypeSelect: {
        all: "All",
        photo: "Photo",
        video: "Video",
      },
      departments: "Departments",
      error: "Failed to load the gallery. Try later",
      departmentsError: "Failed to load the departments. Try later",
      multilinePhoto: "photos/videos",
      photo: "photo/video",
      search: "Search",
      searchByName: "Search by name",
      emptyResults: "No documents were found for your request",
    },
    reportsPage: {
      error: "Failed to load the reports. Try later",
    },
    governmentCleans: {
      error: "Failed to load the reports. Try later",
      search: "Search by documents",
    },
    loading: "Loading...",
    departments: {
      seeBiography: "See biography",
      direction: "Direction",
      centralDepartment: "Central Department",
      structuralDepartments: "Structural departments",
      regionalDepartments: "Regional departments",
      address: "Address",
      phone: "Phone number",
      workSchedule: "Work schedule",
      monThu: "Mon-Thu",
      fri: "Fri",
      break: "Break",
      citizens: "citizens reception",
      description1:
        "During the period of martial law in Ukraine, introduced in accordance with the Decree of the President" +
        ' of Ukraine dated February 24, 2022 No. 64/2022 "On the introduction of martial law in Ukraine" ' +
        "(as amended), the personal reception of citizens by officials of the National Security Service is " +
        "suspended, or, if possible, can take place in the video communication mode.",
      description2:
        "We draw your attention to the fact that, in the event that an air raid signal is announced during " +
        "a personal reception, the person responsible for organizing the reception will stop it and advise " +
        "the visitor to go to the places of civil defense protective structures.",
      description3:
        'At the same time, we note that in accordance with the Law of Ukraine "On Appeals of Citizens", ' +
        "you have the opportunity to send an electronic appeal to the address nspp{'@'}nspp.gov.ua or send " +
        "an appeal in writing to the following address: st. Baseina 1/2-A, Kyiv, 01024.",
      error: "Failed to load the departments. Try later",
      table: {
        caption: "Schedule of personal reception of citizens",
        heading: {
          position: "Position, full name",
          receptionDays: "Reception days",
          receptionTime: "Reception time",
        },
        row1: {
          position: "NMCS Head",
          receptionDays: "The second and fourth half of the month",
        },
        row2: {
          position: "NMCS First Deputy Chairman",
          receptionDays: "First and third Wednesday of the month",
        },
        row3: {
          position: "NMCS Head of Staff",
          receptionDays: "First and third Thursday of the month",
        },
      },
    },
    disputesRegister: {
      columns: {
        order: "Order",
        registrationNumber: "Реєстраційний номер КТС(К)",
        numberAndDate: "Номер та дата розпорядження про реєстрацію КТС (к)",
        name: "Name of the order",
        requirements: "Requirements",
        stage: "Review stage",
      },
      error: "Failed to load Register of collective labor disputes. Try again later",
      searchByKeyWord: "Search by keyword",
      search: "Search",
      emptyResults: "No collective labor disputes were found for your request",
    },
    partiesEntitiesRegister: {
      title: "Parties entities register",
      filters: {
        level: {
          title: "Level",
          default: "All levels",
          national: "national",
          industry: "industry",
          territorial: "territorial",
        },
        region: {
          title: "region",
          default: "All regions",
        },
        date: {
          title: "Acceptance date",
          placeholder: "Choose a date",
        },
      },
      columns: {
        order: "No.",
        organization: "Assessed organization",
        level: "Level",
        region: "Region",
        entityType: "Type of subject of social dialogue",
        legalAddress: "Legal address",
        dateNumberType: "Date, number and solution type",
        numberAndExpirationDate: "Certificate number and validity period",
      },
      error: "Failed to load the register. Try later",
      emptyResults:
        "According to your request, no subjects of parties of trade unions and employers' organizations were found",
      searchByKeyWord: "Search by key word",
    },
    links: {
      scientificWorks: "Scientific works",
      reports: "Reports, analytical reports, ILO recommendations",
      CERecommendations: "Recommendations of the Committee of Ministers of the Council of Europe",
      authoritiesRecommendations:
        "Recommendations, analytical reference materials of authorities, parties of social dialogue",
      roundTables: "Round tables, conferences, speeches",
      presentations: "Presentations",
    },
    independentMediators: {
      emptyResults: "No independent mediators were found for your request",
    },
    persons: {
      certificate: "Certificate number and date of issue",
      error: "Failed to load list. Try again later",
      emptyResults: "No labor arbitrators were found for your request",
      formOfActivity: "Form of activity",
      region: "Region of activity",
      formOfActivitySelect: {
        all: "All forms",
        online: "Online",
        offline: "Offline",
      },
      location: "Region of activity",
      locationDefault: "All regions",
      searchByName: "Search by name",
      search: "Search",
      workExperience: "Work experience",
      bio: {
        error: "Failed to load bio",
        biography: "Biography",
        education: "Education",
        employment: "Employment",
      },
    },
    regulatory: {
      error: "Failed to load the documents. Try later",
      clearFilters: "Reset filter settings",
      emptyResults: "No documents were found for your request",
      makeFilter: "Apply filters",
      filtered: "Filtered out",
      count: "In general",
      docs: "document",
      newDocs: "New legislation documents",
      searchPlaceholder: "Search documents",
      sectionLabel: "Document section",
      sectionPlaceholder: "Select section",
      dateLabel: "Publication date",
      datePlaceholder: "Select date",
      searchButtonText: "Search",
    },
    tabs: {
      vacancies: {
        actual: "Актуальні вакансії",
        results: "Результати конкурсу",
        info: "Інформація для учасників конкурсу",
      },
      regulatory: {
        legislation: "Законодавство",
        activity: "НПА за напрямами діяльності",
        judicialPractice: "Судова практика",
      },
    },
    publicInformation: {
      accordions: {
        title1: "Інформація щодо доступу до публічної інформації",
        title2: "Перелік наборів даних, які підлягають оприлюдненню у формі відкритих даних",
        title3:
          "Відшкодування витрат на копіювання або друк документів, що надаються за запитом на інформацію",
      },
      section1: {
        low: "Відповідно дo Закону України «Про доступ до публічної інформації» публічною інформацією є відображена та задокументована будь-якими засобами та на будь-яких носіях інформація, яка була отримана або створена в процесі виконання суб'єктами владних повноважень своїх обов'язків, передбачених чинним законодавством або яка знаходиться у володінні суб'єктів владних повноважень, інших розпорядників публічної інформації, визначених цим законом.",
        accessGuaranteed: {
          title: "Право на доступ до публічної інформації гарантується:",
          point1:
            "обов'язком розпорядників інформації надавати і оприлюднювати інформацію, окрім випадків передбачених законом;",
          point2:
            "визначенням розпорядником інформації спеціальних структурних підрозділів або посадових осіб, які організовують у встановленому порядку доступ до публічної інформації, якою він володіє;",
          point3: "максимальним спрощенням процедури подання запиту і отримання інформації;",
          point4:
            "доступом до засідань колегіальних суб'єктів владних повноважень, крім випадків, передбачених законодавством;",
          point5:
            "здійсненням парламентського, громадського та державного контролю за дотриманням прав на доступ до публічної інформації;",
          point6:
            "юридичною відповідальністю за порушення законодавства про доступ до публічної інформації.",
        },
        basicPrinciples: {
          title: "Основні принципи забезпечення доступу до публічної інформації",
          description:
            "Надання публічної інформації Національною соціальною сервісною службою України здійснюється у відповідь на інформаційний запит.",
          point1: "прозорість та відкритість діяльності суб'єктів владних повноважень;",
          point2: "вільне отримання та поширення інформації, крім обмежень, встановлених законом;",
          point3:
            "рівноправність, незалежно від ознак раси, політичних, релігійних та інших переконань, статі, етнічного та соціального походження, майнового стану, місця проживання, мовних або інших ознак.",
        },
        informationRequest: {
          title:
            "Запит на отримання інформації складається у довільній формі. При цьому необхідно вказати:",
          point1:
            "ім'я та прізвище запитувача, поштову адресу або адресу електронної пошти, а також номер телефону;",
          point2:
            "опис інформації, яку запитувач хотів би отримати. (Вид, назву, реквізити чи зміст документа, щодо якого зроблено запит)",
          point3: "підпис і дату.",
        },
        appealingDecisions: {
          title: "Порядок оскарження рішень, дій чи бездіяльності розпорядника інформації",
          table: "Таблицею",
          paragraph1:
            'Згідно з Законом "Про доступ до публічної інформації", відповідь на інформаційний запит має бути надано не пізніше п\'яти робочих днів з дня отримання запиту.',
          paragraph2:
            "У разі, якщо запит стосується надання великого обсягу інформації або потребує пошуку інформації серед значної кількості даних, строк розгляду запиту може бути продовжено до 20 робочих днів з обґрунтуванням такого продовження.",
          paragraph3:
            'Відповідно до статті 23 Закону "Про доступ до публічної інформації", рішення, дії чи бездіяльність розпорядників інформації можуть бути оскаржені до керівника розпорядника, вищого органу або суду.',
          paragraph4:
            "Оскарження рішень, дій чи бездіяльності розпорядників інформації до суду здійснюється відповідно до Кодексу адміністративного судочинства України.",
          paragraph5:
            "Також з метою правильного розуміння запитувачами понять «запит на інформацію» та «звернення громадян» пропонуємо ознайомитися із {link}, в якій наведені їх відмінні ознаки.",
          paragraph6:
            "Порядок забезпечення доступу до публічної інформації в НСПП визначений згідно Положення про забезпечення доступу до публічної інформації у Національній службі посередництва і примирення, її відділеннях в Автономній Республіці Крим та областях, затвердженого наказом НСПП від 10.10.2012 № 118.",
        },
      },
      section2: {
        low: "Перелік наборів даних, що перебувають у володінні НСПП, які підлягають оприлюдненню у формі відкритих даних затверджено розпорядженням НСПП від 03.05.2023 № 32.",
      },
      section3: {
        paragraph:
          "Порядок відшкодування фактичних витрат на копіювання або друк документів, що надаються за запитами на інформацію у НСПП Інструкція про порядок відшкодування витрат на копіювання або друк документів, що надаються за запитом на інформацію, розпорядником якої є НСПП, її відділення в АРК та областях, та Розміру цих фактичних витрат, затверджена наказом НСПП від 10.10.2012 № 120.",
        documents: {
          document1:
            "Заявка на виписку рахунка для здійснення оплати витрат на копіювання або друк документів, що надаються за запитом на інформацію",
          document2:
            "Рахунок для здійснення оплати витрат на копіювання або друк документів, що надаються за запитом на інформацію",
          document3:
            "Розмір фактичних витрат на копіювання або друк документів, що надаються за запитами на інформацію, розпорядником якої є НСПП, її відділення",
        },
      },
      form: {
        publicInformationRequest: "Request for public information",
        description:
          "Громадяни України мають право звернутися до Національної служби посередництва і примирення та її органів із запитом на доступ до публічної інформації у електронній та письмовій формі. ",
        low: "Закон України «Про доступ до публічної інформації»",
        mandatoryField: "Indicates a required field",
        questioner: "The questioner",
        legalEntity: {
          individual: "Individual",
          legalEntity: "Legal entity",
          associationOfCitizensWithoutStatus:
            "Association of citizens without the status of a legal entity",
        },
        personalInfo: "Personal data of the requester",
        firstName: "First name",
        lastName: "Last name",
        surname: "Surname",
        phone: "Phone",
        email: "Email",
        address: "Postal address",
        generalDescriptionOfRequest: "General description of the request",
        generalDescription: "Type, name, details or content of the requested document",
        getAnswerTo: "Get an answer to",
        respondBy: {
          email: "Email",
          postalAddress: "Postal address",
        },
        send: "Send",
        invalidEmail: "Invalid email format.",
        invalidPhone: "Invalid phone format.",
        invalidRequest: "Please decrease your input to 4500 symbols.",
        successMessage: "Thank you for your request.",
        errorMessage: "We could not send your request, please try again.",
      },
    },
    tags: {
      title: "Tags",
    },
    submitDocuments: {
      requiredField: "Indicates a required field",
      title1: "Employees' side",
      title2: "Employers' side",
      subtitle2:
        "(Власник або відповідний вищестоящий орган управління, який має право прийняти рішення)",
      title3: "Безпосередній роботодавець",
      title4: "Кількість працівників на підприємстві",
      title5:
        "Кількість працівників, що виявили намір вступити у колективний трудовий спір (конфлікт)",
      title6:
        " Кількість працівників у структурному, відокремленому підрозділі, окремій категорії" +
        "найманих працівників, що виявили бажання вступити в колективний трудовий спір (конфлікт)",
      subtitle6: "(у випадку, коли в КТС (к) вступає не все підприємство, а окремі підрозділи)",
      title7: "Вимоги найманих працівників",
      title8:
        "Документ, що підтверджує факт надання висунутих найманими працівниками вимог роботодавцю",
      subtitle8:
        "(виписка ШКІ у разі надсилання рекомендованим повідомленням та скан листа, виписка з листа" +
        "електронної пошти з підтвердженням факту надсилання такого листа та доказом, що ця пошта" +
        "належить роботодавцю тощо)",
      title9:
        "Інші документи, які мають значення для вирішення колективного трудового спору (конфлікту)",
      subtitle9:
        "(колективні договори, галузеві угоди, інші документи щодо яких висуваються вимоги і які" +
        "мають значення для вирішення КТС (к).",
      title10:
        "Документи, в яких роботодавець або уповноважена ним особа, організація роботодавців," +
        "об'єднання організацій роботодавців надали відповідь найманим працівникам щодо можливості " +
        "задоволення висунутих вимог",
      title11: "Рівень спору",
      info1:
        "Наймані працівники підприємства, установи, організації або структурного, відокремленого " +
        "підрозділу або окремої категорії найманих працівників підприємства, установи," +
        "організації (повна назва) або профспілкової чи іншої уповноваженої найманими " +
        "працівниками організації (повна назва).",
      info2:
        "Повне найменування сторони роботодавця, уповноваженого приймати рішення щодо задоволення " +
        "висунутих найманими працівниками вимог в колективному трудовому спорі (конфлікті).",
      info3:
        " У цьому пункті зазначається керівник (роботодавець)/ліквідаційна комісія підприємства," +
        "установи, організації, якому було висунуто вимоги. У цьому пункті зазначається " +
        "уповноважена посадова особа (орган) до якої висунуто вимоги.",
      info4:
        "Для отримання відповідної інформації, рекомендується зробити запит до роботодавця. У " +
        "разі не надання роботодавцем зазначеної інформації, звертатися до Пенсійного Фонду" +
        "України, органів статистики, Державної служби статистики інших органів, що володіють " +
        "такою інформацією та уповноважені її надати.",
      info5:
        "Подається у разі, коли в КТС (к) вступає структурний, відокремлений підрозділ підприємства, окрема категорія найманих працівників.",
      info6: `Формування та затвердження вимог працівників або профспілки і визначення органу чи особи, які будуть представляти їх інтереси, загальними зборами або конференцією здійснюється наступним чином:
        <ul> 
        <li>збори скликаються з ініціативи виборного профспілкового органу чи іншого уповноваженого працівниками органу або ініціативної групи працівників, а також за пропозицією не менш як третини від загальної кількості штатних працівників.</li>
        <li>збори працівників є правоможними, якщо в них бере участь не менше половини працівників підприємства, установи, організації, окремого структурного підрозділу, окремої категорії працівників;</li>
        <li>конференції представників працівників можуть скликатися, у випадках, коли скликання зборів утруднене через багатозмінність або територіальну роз’єднаність цехів, відділів, дільниць та інших структурних підрозділів; </li>
        <li>конференція представників працівників є правоможною, якщо в ній бере участь не менше двох третин обраних представників працівників (делегатів конференції) або формуються шляхом збору підписів і вважаються чинними за наявності не менше половини підписів членів трудового колективу підприємства, установи, організації чи їх структурного підрозділу.</li>
        <li>у випадках, коли інтереси працівників представляє профспілка, об’єднання профспілок - рішенням виборного органу відповідної профспілки, об’єднання профспілок.</li>
        </ul>
        Рішення загальних зборів (конференції) найманих працівників приймаються відкритим або таємним голосуванням та вважаються чинними у разі, коли за них проголосувала така кількість учасників загальних зборів (конференції), яка складає за їх повноваженнями не менше ніж половини працюючих членів трудового колективу підприємства, установи, організації чи їх окремого структурного підрозділу.<br>
        Вимоги працівників, профспілки чи об’єднання профспілок оформляються протоколом (на слові протоколом має бути посилання на файл з протоколом, доступний для завантаження), який підписується головою і секретарем зборів (конференції) і надсилаються стороні роботодавця. До протоколу зборів (конференції) додаються матеріали реєстрації їх учасників (список працівників, які були присутніми на зборах, або список представників працівників та протокол (висновок) мандатної (лічильної) комісії).<br>
        В роботі зборів має право брати участь сторона роботодавця, представники місцевих органів виконавчої влади, органів місцевого самоврядування, працівники Національної служби посередництва і примирення та за згодою учасників даних загальних зборів (конференції) інші представники громадських об’єднань.<br>
        Затвердження вимог працівників та визначення органу чи особи, які будуть представляти їх інтереси може бути здійснено шляхом збору підписів (зразок підписного листа за посиланням).<br>
        Профспілковий орган чи ініціативна група працівників виготовляють підписні листи, в яких зазначаються вимоги, які пропонуються до висунення працівників та орган чи особа, які пропонуються для представлення інтересів працівників. Працівники розглядають пропозиції і добровільно ставлять свої підписи в підписному листі або накладають удосконалений/кваліфікований електронний підпис, у разі виготовлення підписних листів в електронному вигляді.<br>
        Після завершення збору підписів працівників результати збору підписів узагальнюються органом, уповноваженим представляти інтереси найманих працівників, про що складається протокол.<br>
        Вимоги працівників вважаються чинними за наявності не менше половини підписів членів трудового колективу підприємства, установи, організації чи їх структурного підрозділу або окремої категорії працівників.`,
      info7:
        "Форма для прикріплення файла (-ів) із документом, що підтверджує намір найманих працівників вступити в КТС(к) (протокол загальних зборів (конференції) найманих працівників з відповідними підписними листами, іншими документами, що підтверджують правоможність зборів, протокол засідання конференції представників (делегатів конференції) найманих працівників з відповідними підписними листами та документами, що підтверджують повноваження делегатів",
      info8: `Рекомендації щодо формування вимог:
      <ul>
      <li>Не допускається формування вимог у майбутньому часі, наприклад «Забезпечити стабільну виплату заробітної плати»;</li>
      <li>Не допускається загальний характер вимог, наприклад «Роботодавцю дотримуватись вимог Конституції України, Закону України «Про професійні спілки, їх права та гарантії діяльності», колективного договору від 23.08.2023 №49 між найманими працівниками і роботодавцем».</li>
      <li>У таких випадках слід зазначити статтю (-ті), їх частину(-и), абзац (-и) тощо, які порушуються або виписати порушення, яке можна ідентифікувати у законодавстві України, наприклад «Роботодавцю виконати пункт 5.8 Колективного договору від 23.08.2023 №49 між найманими працівниками і роботодавцем», «Виплатити заборгованість по заробітній платі за період з січня 2018 року по лютий 2019 року».</li>
      </ul>`,
      info9: `Вимоги працівників, профспілки чи об’єднання профспілок оформляються відповідним протоколом і надсилаються стороні роботодавця.
        <br>Важливо зафіксувати дату вручення вимог стороні роботодавця (в журналі або в інших реєстраційних формах обліку вхідної кореспонденції або в формі підпису сторони роботодавця на другому екземплярі вимог, що залишається в представника найманих працівників, або поштовим документом (квитанцією), що підтверджує відправку вимог рекомендованим листом, або на офіційну електронну адресу роботодавця). Незалежно від форми реєстрації, рекомендується запросити в роботодавця реєстраційний номер і дату.
        <br>З моменту отримання вимог Стороною роботодавця починається відлік строку розгляду вимог.
        <br>Днем одержання вимог вважається день їх реєстрації у журналі або книзі вхідної кореспонденції, яка ведеться відповідним структурним підрозділом на підприємстві, в установі, організації чи безпосередньо сторона спору, визначена Законом.
        <br>В процесі реєстрації вимог, що надійшли на розгляд стороні роботодавця, на протоколі, в якому вони зазначені, робиться відповідна помітка щодо дати реєстрації та вхідного номера.
        <br>У випадку надходження вимог поштою рекомендованим листом з повідомленням днем одержання вимог рахуватиметься день вручення Стороні роботодавця відповідного конверта (із вкладеними в нього вимогами), про що свідчитиме його особистий підпис або підпис працівника, який відповідає за діловодство, на поштовому повідомленні про вручення, індекс ШКІ або аналогічна позначка у сервісі трекінгу із зазначенням дати отримання. Записи у журналі вхідної документації в такому випадку здійснюються в день фактичного одержання поштового відправлення.
        <br>Згідно статті 5 Закону України «Про порядок вирішення колективних трудових спорів (конфліктів)» сторона роботодавця зобов’язана розглянути вимоги працівників, категорій працівників, колективу працівників чи профспілки та повідомити їх представників про своє рішення у триденний строк з дня одержання вимог (перебіг строку починається з наступного дня після дати одержання вимог).`,
      info10: `Сторони КТС (к):
      <ul>
      <li>на виробничому рівні - наймані працівники (окремі категорії найманих працівників) підприємства, установи, організації чи їх структурних підрозділів або первинна профспілкова чи інша уповноважена найманими працівниками організація та роботодавець. За дорученням інтереси роботодавця у колективному трудовому спорі (конфлікті) може представляти інша особа, організація роботодавців, об’єднання організацій роботодавців;</li>
      <li>на територіальному рівні - наймані працівники підприємств, установ, організацій однієї або декількох адміністративно-територіальних одиниць чи профспілки, їх об’єднання або інші уповноважені цими найманими працівниками органи та організації роботодавців, їх об’єднання або місцеві органи виконавчої влади, що діють на території відповідної адміністративно-територіальної одиниці;</li>
      <li>на галузевому рівні - наймані працівники підприємств, установ, організацій однієї або декількох галузей (професій) чи профспілки, їх об’єднання або інші уповноважені цими найманими працівниками органи та організації роботодавців, їх об’єднання або центральні органи виконавчої влади;</li>
      <li>на національному рівні - наймані працівники однієї або декількох галузей (професій) чи профспілки або їх об’єднання чи інші уповноважені найманими працівниками органи та всеукраїнські об’єднання організацій роботодавців або Кабінет Міністрів України.</li></ul>`,
      fileConditions:
        "Прикріпити документ можливо у наступних форматах: jpg, jpeg, png, docx, pdf.\n" +
        "Максимальний розмір файла - 5 мб",
      fileFormat: "mb",
      fileError: "An error occurred while uploading the file.",
      fileSizeError: "File size exceeds",
      addFile: "Add file",
      formElements: {
        employees: "Employees",
        authorizedPersonToRepresentTheInterestsOfEmployees:
          "Person authorized to represent the interests of employees",
        email: "Email",
        phone: "Phone number",
        phoneIfAvailable: "Phone number (if available)",
        authorizedPersonToRepresentTheInterestsOfEmployer:
          "Person authorized to represent the interests of the employer",
        employer: "Employer",
        employerInfo:
          "Безпосередній роботодавець і власник або відповідний вищестоящий орган управління, який має право прийняти рішення співпадають",
        totalNumberOfEmployeesInNumbers: "Total number of employees in numbers",
        totalNumberOfEmployeesInWords: "Total number of employees in words",
        checkbox2:
          "Роботодавець не надав інформацію про організаційну структуру підприємства із зазначенням кількості окремої категорії найманих працівників, структурного, відокремленого підрозділу підприємства, наймані працівники якого вступають у колективний трудовий спір (конфлікт)",
        numberOfEmployeesInNumbers: "Number of employees in numbers",
        numberOfEmployeesInWords: "Number of employees in words",
        requirementsOfEmployees: "Requirements of employees",
        checkbox3: "Роботодавець не надав відповідь у передбачений законодавством строк",
      },
    },
    regions: {
      vi: "Vinnytsia",
      vo: "Volynsk",
      dp: "Dnipropetrovsk",
      do: "Donetsk",
      zh: "Zhytomyr",
      uz: "Zakarpattia",
      zp: "Zaporizhzhia",
      if: "Ivano-Frankivsk",
      ky: "Kyivska",
      ki: "Kirovohradska",
      lg: "Luhansk",
      lv: "Lviv",
      mk: "Mykolaivska",
      od: "Odeska",
      pl: "Poltava",
      rv: "Rivnenska",
      su: "Sumska",
      te: "Ternopilska",
      kh: "Kharkivska",
      ks: "Khersonska",
      km: "Khmelnytska",
      ck: "Cherkasia",
      cv: "Chernivetska",
      cn: "Chernihivska",
    },
    readMore: "Read More",
    readLess: "Read Less",
    datepicker: {
      select: "Select",
      cancel: "Cancel",
    },
  },
  uk: {
    siteTitle: "НСПП",
    siteTitleFull: "Національна служба посередництва і примирення",
    siteTitleAll1: "Національна служба",
    siteTitleAll2: "посередництва і примирення",
    ofisialWebsite: "Офіційний веб-сайт",
    license1: "Весь контент доступний за ліцензією Creative Commons",
    license2: "Attribution 4.0 International license, якщо не зазначено інше",
    test_mode: "Сайт працює в тестовому режимі",
    breadcrumbs: {
      main: "Головна",
      aboutUs: "Про нас",
      activities: "Напрями діяльності",
      activityDisputes: "Колективні спори (конфлікти)",
      socialDialogue: "Соціальний діалог",
      pressCenter: "Пресцентр",
      news: "Новини",
      mediaLibrary: "Медіатека",
      events: "Анонси",
      gallery: "Медіатека",
      pubInMedia: "Публікації у ЗМІ",
      reports: "Звіти про діяльність",
      governmentCleans: "Очищення влади",
      forPublic: "Для громадськості",
      resources: "Реєстри та ресурси",
      vacancies: "Вакансії та конкурси",
    },
    common: {
      share: "Поділитись",
      tags: "Теги",
      sections: "Розділи",
      consent:
        "Реєструючи звернення, даю згоду на обробку і використання персональних даних згідно з законодавством України",
      fieldIsRequired: "Обовʼязкове поле.",
    },
    galleries: {
      photoTitle: "Фото",
      videoTitle: "Відео",
      allPhotoLink: "Всі фото",
      allVideoLink: "Всі відео",
    },
    menu: {
      aboutUs: {
        title: "Про нас",
        mission: "Місія, візія, цінності",
        stakeholders: "Наші стейкхолдери",
        history: "Історія",
        cooperation: "Міжнародна співпраця",
        departments: "Структура",
        contacts: "Контакти",
        reports: "Звіти про діяльність",
      },
      areasOfActivities: {
        title: "Напрями діяльності",
        collectiveDisputes: "Колективні трудові спори",
        disputesConflicts: "Поняття колективного трудового спору (конфлікту)",
        alorithm: "Алгоритм вирішення колективного  трудового спору (конфлікту)",
        trainingLaborArbitrators: "Підготовка трудових арбітрів та незалежних посередників",
        prevention: "Запобігання виникненню колективних трудових спорів",
        socialDialogue: "Соціальний діалог",
        concept: "Поняття соціального діалогу",
        legislation: "Законодавство України про соціальний діалог",
        principles: "Основні принципи соціального діалогу",
        determination: "Визначення критеріїв репрезентативності",
        peculiarities:
          "Особливості подання документів і продовження дії свідоцтв в умовах воєнного стану",
        socioEconomicCouncil: "Національна тристороння соціально-економічна рада",
        foreignExperience: "Зарубіжний досвід",
      },
      regulatory: "Нормативна база",
      resources: {
        title: "Реєстри та ресурси",
        bulletin: "Бюлетень НСПП",
        collectiveLaborDisputesRegister: "Реєстр колективних трудових спорів (конфліктів)",
        partiesEntitiesRegister:
          "Реєстр репрезентативних суб'єктів сторін профспілок та організацій роботодавців",
        laborArbitrators: "Список трудових арбітрів",
        independentMediators: "Список незалежних посередників",
        links: "Корисні посилання",
      },
      forPublic: {
        title: "Для громадськості",
        appealOfCitizens: "Звернення громадян",
        governmentProcurement: "Державні закупівлі",
        publicInformation: "Доступ до публічної інформації",
        actualVacancies: "Вакансії та конкурси",
        governmentCleans: "Очищення влади",
        submitDocuments: "Подати документи на реєстрацію КТС(к)",
        antiCorruptionPolicy: "Запобігання та протидія корупції",
      },
      pressCenter: {
        title: "Пресцентр",
        news: "Новини",
        mediaLibrary: "Медіатека",
        announcements: "Анонси",
        pubInMedia: "Публікації у ЗМІ",
        popularTags: "Популярні новинні теги",
      },
    },
    home: {
      announcements: "Анонси",
      all_announcements: "Всі анонси",
      electronicAppeal: "Електронне звернення",
      sendAppeal: "Подати звернення",
      submitElectronicAppeal:
        "Подайте свої запитання, скарги, пропозиції та отримайте необхідну інформацію з будь-якого місця, де є доступ до інтернету.",
      license:
        "Весь контент доступний за ліцензією Creative Commons Attribution 4.0 International license, якщо не зазначено інше",
    },
    stakeholders: {
      allStakeholdersLink: "Всі стейкхолдери",
    },
    emptyResults: {
      defaultTitle: "За вашим запитом нічого не знайдено",
      noDocuments: "За вашим запитом не знайдено документів",
      action: "Що з цим робити",
      step1: "Переконайтеся, що всі слова написані правильно.",
      step2: "Спробуйте інші ключові слова.",
      step3: "Спробуйте використати загальніші слова.",
    },
    regulatoryFramework: {
      title:
        "Отримайте доступ до повної нормативної бази - саме ті документи та закони, які вам потрібні",
      text:
        "Ми забезпечуємо вам необхідну інформацію по документах, що регулюють діяльність державних органів" +
        " та установ у сфері спорів та конфліктів",
      link: "Перейти до бази",
      newDocs: "Нові законодавчі документи",
      count: "Загалом",
      docs: "документ",
      oftenSearch: "Часто шукають",
      filters: {
        searchPlaceholder: "Пошук по документах",
        sectionLabel: "Розділ документу",
        sectionPlaceholder: "Обрати розділ",
        sectionOptions: {
          legislation: "Законодавство",
          activity: "НПА за напрямами діяльності",
          judicialPractice: "Судова практика",
        },
        dateLabel: "Дата прийняття",
        datePlaceholder: "Обрати дату",
        searchButtonText: "Знайти",
      },
    },
    news: {
      news: "Новини",
      mainNews: "Головні новини",
      newsByTopic: "Новини за темою",
      error: "Не вдалося завантажити новини. Спробуйте пізніше",
      newsSub: {
        digest: "Щотижня ділимося новинами",
        subscribe:
          "Кожної неділі ми ділимося цікавим з роботи нашої служби. Підпишіться, щоб отримувати листи з нашими новинами.",
        agreement: "Натискаючи кнопку, ви автоматично погоджуєтесь на обробку персональних даних",
      },
      subscription: {
        placeholder: "Ваш email",
        subscribeButton: "Підписатися",
        emailExists: "Ви вже підписані",
        error: "Не вдалося підписатися",
        success: "Ви успішно підписалися",
      },
      allNews: "Всі новини",
      searchByName: "Пошук новин по назві",
      datePickerLabel: "Дата публікації",
      datePickerPlaceholder: "Обрати дату",
      stayUpToDate: "Будь в курсі новин",
      publicationDate: "датою публікації",
      tags: "тегом",
      foundBy: "Знайдено за ",
      searchQuery: "пошуковим запитом",
      clearFilters: "Очистити результати",
      foundResults: "Знайдено за пошуковим запитом",
      searchResults: "Результати пошуку",
      emptyResults: "За вашим запитом не знайдено новин",
    },
    events: {
      error: "Не вдалося завантажити анонс. Спробуйте пізніше",
      calendar: "Додати в календар",
      date: "Дата",
      time: "Час",
      location: "Місце проведення",
      upcoming_events: "Найближчі події",
      filter: {
        title: "Знайдено за автором",
        clear: "Очистити результати",
      },
    },
    gallery: {
      allDepartments: "Усі відділення",
      contentType: "Тип контенту",
      contentTypeSelect: {
        all: "Усі",
        photo: "Фото",
        video: "Відео",
      },
      departments: "Відділення",
      error: "Не вдалося завантажити галерею. Спробуйте пізніше",
      departmentsError: "Не вдалося завантажити відділення. Спробуйте пізніше",
      multilinePhoto: "фото/відео",
      photo: "фото/відео",
      search: "Пошук",
      searchByName: "Пошук по назві",
      emptyResults: "За вашим запитом не знайдено документів",
    },
    reportsPage: {
      error: "Не вдалося завантажити звіти. Спробуйте пізніше",
    },
    governmentCleans: {
      error: "Не вдалося завантажити звіти. Спробуйте пізніше",
      search: "Пошук по документах",
    },
    loading: "Завантаження...",
    departments: {
      seeBiography: "Подивитися біографію",
      direction: "Керівництво",
      centralDepartment: "Центральний апарат",
      structuralDepartments: "Структурні підрозділи",
      regionalDepartments: "Регіональні відділення",
      address: "Адреса",
      phone: "Телефон",
      workSchedule: "Графік роботи",
      monThu: "Пн-Чт",
      fri: "Пт",
      break: "Перерва",
      citizens: "Прийом громадян",
      description1:
        "На період дії воєнного стану в Україні, введеного відповідно до Указу Президента\n" +
        "              України від 24 лютого 2022 року № 64/2022 «Про введення воєнного стану в Україні» (зі\n" +
        "              змінами), проведення особистого прийому громадян посадовими особами НСПП\n" +
        "              призупиняється, або, за можливості, може відбуватися у режимі відеозв’язку.",
      description2:
        "Звертаємо вашу увагу, у разі якщо під час проведення особистого прийому оголошено\n" +
        "              сигнал повітряної тривоги, особа, відповідальна за організацію прийому, припиняє його\n" +
        "              проведення та рекомендує відвідувачу пройти до місць захисних споруд цивільного\n" +
        "              захисту.",
      description3:
        'Водночас зазначаємо, що відповідно до Закону України "Про звернення громадян" у Вас є\n' +
        "              можливість надіслати електронне звернення на адресу nspp{'@'}nspp.gov.ua або надіслати\n" +
        "              звернення у письмовій формі на адресу: вул. Басейна 1/2-А, м. Київ, 01024.",
      error: "Не вдалося завантажити відділення. Спробуйте пізніше",
      table: {
        caption: "ГРАФІК ОСОБИСТОГО ПРИЙОМУ ГРОМАДЯН",
        heading: {
          position: "Посада, ПІБ",
          receptionDays: "Дні прийому",
          receptionTime: "Час прийому",
        },
        row1: {
          position: "Голова НСПП",
          receptionDays: "Другий та четвертий вівторок місяця",
        },
        row2: {
          position: "Перший заступник голови НСПП",
          receptionDays: "Перша та третя середа місяця",
        },
        row3: {
          position: "Керівник апарату НСПП",
          receptionDays: "Перший та третій четвер місяця",
        },
      },
    },
    disputesRegister: {
      columns: {
        order: "№ з/п",
        registrationNumber: "Реєстраційний номер КТС(К)",
        numberAndDate: "Номер та дата розпорядження про реєстрацію КТС (к)",
        name: "Назва розпорядження",
        requirements: "Вимоги",
        stage: "Стадія розгляду",
      },
      error: "Не вдалося завантажити Реєстр колективних трудових спорів. Спробуйте пізніше",
      searchByKeyWord: "Пошук по ключовому слову",
      search: "Знайти",
      emptyResults: "За вашим запитом не знайдено колективних трудових спорів",
    },
    partiesEntitiesRegister: {
      title: "Реєстр репрезентативних суб'єктів сторін профспілок та організацій роботодавців",
      filters: {
        level: {
          title: "Рівень",
          default: "Усі рівні",
          national: "національний",
          industry: "галузевий",
          territorial: "територіальний",
        },
        region: {
          title: "Область",
          default: "Усі області",
        },
        date: {
          title: "Дата прийняття",
          placeholder: "Обрати дату",
        },
      },
      columns: {
        order: "№ з/п",
        organization: "Організація, що пройшла оцінку",
        level: "Рівень",
        region: "Область",
        entityType: "Вид суб’єкта соціального діалогу",
        legalAddress: "Юридична адреса",
        dateNumberType: "Дата, номер та вид рішення",
        numberAndExpirationDate: "Номер та термін дії свідоцтва",
      },
      error: "Не вдалося завантажити реєстр. Спробуйте пізніше",
      emptyResults:
        "За вашим запитом не знайдено  суб’єктів сторін профспілок та організацій роботодавців",
      searchByKeyWord: "Пошук по ключовому слову",
    },
    links: {
      scientificWorks: "Наукові праці",
      reports: "Звіти, аналітичні довідки, рекомендації МОП",
      CERecommendations: "Рекомендації Комітету Міністрів Ради Європи",
      authoritiesRecommendations:
        "Рекомендації, аналітично довідкові матеріали органів влади, сторін соціального діалогу",
      roundTables: "Круглі столи, конференції, виступи",
      presentations: "Презентації",
    },
    independentMediators: {
      emptyResults: "За вашим запитом не знайдено незалежних посередників",
    },
    persons: {
      certificate: "Номер та дата видачі свідоцтва",
      error: "Не вдалося завантажити список. Спробуйте пізніше",
      emptyResults: "За вашим запитом не знайдено трудових арбітрів",
      region: "Регіон діяльності",
      formOfActivity: "Форма діяльності",
      formOfActivitySelect: {
        all: "Усі форми",
        online: "Онлайн",
        offline: "Офлайн",
      },
      location: "Регіон здійснення діяльності",
      locationDefault: "Усі регіони",
      searchByName: "Пошук по імені",
      search: "Знайти",
      workExperience: "Стаж роботи",
      bio: {
        error: "Не вдалося завантажити біографію",
        biography: "Біографія",
        education: "Освіта",
        employment: "Трудова діяльність",
      },
    },
    regulatory: {
      error: "Не вдалося завантажити документи. Спробуйте пізніше",
      clearFilters: "Скинути налаштування фільтрів",
      emptyResults: "За вашим запитом не знайдено документів",
      makeFilter: "Застосувати фільтри",
      search: "Пошук по документах",
      filtered: "Відфільтровано",
      count: "Загалом",
      docs: "документ",
      newDocs: "Нові законодавчі документи",
      searchPlaceholder: "Пошук по документах",
      sectionLabel: "Розділ документу",
      sectionPlaceholder: "Обрати розділ",
      dateLabel: "Дата прийняття",
      datePlaceholder: "Обрати дату",
      searchButtonText: "Знайти",
    },
    tabs: {
      vacancies: {
        actual: "Актуальні вакансії",
        results: "Результати конкурсу",
        info: "Інформація для учасників конкурсу",
      },
      regulatory: {
        legislation: "Законодавство",
        activity: "НПА за напрямами діяльності",
        judicialPractice: "Судова практика",
      },
    },
    publicInformation: {
      accordions: {
        title1: "Інформація щодо доступу до публічної інформації",
        title2: "Перелік наборів даних, які підлягають оприлюдненню у формі відкритих даних",
        title3:
          "Відшкодування витрат на копіювання або друк документів, що надаються за запитом на інформацію",
      },
      section1: {
        low: "Відповідно дo Закону України «Про доступ до публічної інформації» публічною інформацією є відображена та задокументована будь-якими засобами та на будь-яких носіях інформація, яка була отримана або створена в процесі виконання суб'єктами владних повноважень своїх обов'язків, передбачених чинним законодавством або яка знаходиться у володінні суб'єктів владних повноважень, інших розпорядників публічної інформації, визначених цим законом.",
        accessGuaranteed: {
          title: "Право на доступ до публічної інформації гарантується:",
          point1:
            "обов'язком розпорядників інформації надавати і оприлюднювати інформацію, окрім випадків передбачених законом;",
          point2:
            "визначенням розпорядником інформації спеціальних структурних підрозділів або посадових осіб, які організовують у встановленому порядку доступ до публічної інформації, якою він володіє;",
          point3: "максимальним спрощенням процедури подання запиту і отримання інформації;",
          point4:
            "доступом до засідань колегіальних суб'єктів владних повноважень, крім випадків, передбачених законодавством;",
          point5:
            "здійсненням парламентського, громадського та державного контролю за дотриманням прав на доступ до публічної інформації;",
          point6:
            "юридичною відповідальністю за порушення законодавства про доступ до публічної інформації.",
        },
        basicPrinciples: {
          title: "Основні принципи забезпечення доступу до публічної інформації",
          description:
            "Надання публічної інформації Національною соціальною сервісною службою України здійснюється у відповідь на інформаційний запит.",
          point1: "прозорість та відкритість діяльності суб'єктів владних повноважень;",
          point2: "вільне отримання та поширення інформації, крім обмежень, встановлених законом;",
          point3:
            "рівноправність, незалежно від ознак раси, політичних, релігійних та інших переконань, статі, етнічного та соціального походження, майнового стану, місця проживання, мовних або інших ознак.",
        },
        informationRequest: {
          title:
            "Запит на отримання інформації складається у довільній формі. При цьому необхідно вказати:",
          point1:
            "ім'я та прізвище запитувача, поштову адресу або адресу електронної пошти, а також номер телефону;",
          point2:
            "опис інформації, яку запитувач хотів би отримати. (Вид, назву, реквізити чи зміст документа, щодо якого зроблено запит)",
          point3: "підпис і дату.",
        },
        appealingDecisions: {
          title: "Порядок оскарження рішень, дій чи бездіяльності розпорядника інформації",
          table: "Таблицею",
          paragraph1:
            'Згідно з Законом "Про доступ до публічної інформації", відповідь на інформаційний запит має бути надано не пізніше п\'яти робочих днів з дня отримання запиту.',
          paragraph2:
            "У разі, якщо запит стосується надання великого обсягу інформації або потребує пошуку інформації серед значної кількості даних, строк розгляду запиту може бути продовжено до 20 робочих днів з обґрунтуванням такого продовження.",
          paragraph3:
            'Відповідно до статті 23 Закону "Про доступ до публічної інформації", рішення, дії чи бездіяльність розпорядників інформації можуть бути оскаржені до керівника розпорядника, вищого органу або суду.',
          paragraph4:
            "Оскарження рішень, дій чи бездіяльності розпорядників інформації до суду здійснюється відповідно до Кодексу адміністративного судочинства України.",
          paragraph5:
            "Також з метою правильного розуміння запитувачами понять «запит на інформацію» та «звернення громадян» пропонуємо ознайомитися із {link}, в якій наведені їх відмінні ознаки.",
          paragraph6:
            "Порядок забезпечення доступу до публічної інформації в НСПП визначений згідно Положення про забезпечення доступу до публічної інформації у Національній службі посередництва і примирення, її відділеннях в Автономній Республіці Крим та областях, затвердженого наказом НСПП від 10.10.2012 № 118.",
        },
      },
      section2: {
        low: "Перелік наборів даних, що перебувають у володінні НСПП, які підлягають оприлюдненню у формі відкритих даних затверджено розпорядженням НСПП від 03.05.2023 № 32.",
      },
      section3: {
        paragraph:
          "Порядок відшкодування фактичних витрат на копіювання або друк документів, що надаються за запитами на інформацію у НСПП Інструкція про порядок відшкодування витрат на копіювання або друк документів, що надаються за запитом на інформацію, розпорядником якої є НСПП, її відділення в АРК та областях, та Розміру цих фактичних витрат, затверджена наказом НСПП від 10.10.2012 № 120.",
        documents: {
          document1:
            "Заявка на виписку рахунка для здійснення оплати витрат на копіювання або друк документів, що надаються за запитом на інформацію",
          document2:
            "Рахунок для здійснення оплати витрат на копіювання або друк документів, що надаються за запитом на інформацію",
          document3:
            "Розмір фактичних витрат на копіювання або друк документів, що надаються за запитами на інформацію, розпорядником якої є НСПП, її відділення",
        },
      },
      form: {
        publicInformationRequest: "Запит на публічну інформацію",
        description:
          "Громадяни України мають право звернутися до Національної служби посередництва і примирення та її органів із запитом на доступ до публічної інформації у електронній та письмовій формі. ",
        low: "Закон України «Про доступ до публічної інформації»",
        mandatoryField: "Позначає обов'язкове поле",
        questioner: "Запитувач",
        legalEntity: {
          individual: "Фізична особа",
          legalEntity: "Юридична особа",
          associationOfCitizensWithoutStatus: "Об’єднання громадян без статусу юридичної особи",
        },
        personalInfo: "Особисті дані запитувача",
        firstName: "Ім'я",
        lastName: "Прізвище",
        surname: "По-батькові",
        phone: "Телефон",
        email: "Електронна адреса",
        address: "Поштова адреса",
        generalDescriptionOfRequest: "Загальний опис запиту",
        generalDescription: "Вид, назва, реквізити чи зміст документа, що запитується",
        getAnswerTo: "Отримати відповідь на",
        respondBy: {
          email: "Email",
          postalAddress: "Поштову адресу",
        },
        send: "Послуга тимчасово недоступна",
        invalidEmail: "Невірний формат електронної пошти.",
        invalidPhone: "Невірний формат номеру телефону.",
        invalidRequest: "Будь ласка, зменшіть текст до 4500 символів.",
        successMessage: "Дякуємо за Ваш запит.",
        errorMessage: "Ми не змогли відправити Ваш запит, спробуйте ще раз.",
      },
    },
    reportCorruption: {
      name: "ПІБ",
      work: "Місце роботи",
      position: "Посада",
      text: "Обставини, що свідчать про факти вчинення корупційного або пов'язаного з корупцією правопорушення",
    },
    tags: {
      title: "Теги",
    },
    submitDocuments: {
      requiredField: "Позначає обов'язкове поле",
      title1: "Сторона найманих працівників",
      title2: "Сторона роботодавців",
      subtitle2:
        "(Власник або відповідний вищестоящий орган управління, який має право прийняти рішення)",
      title3: "Безпосередній роботодавець",
      title4: "Кількість працівників на підприємстві",
      title5:
        "Кількість працівників, що виявили намір вступити у колективний трудовий спір (конфлікт)",
      title6:
        " Кількість працівників у структурному, відокремленому підрозділі, окремій категорії" +
        "найманих працівників, що виявили бажання вступити в колективний трудовий спір (конфлікт)",
      subtitle6: "(у випадку, коли в КТС (к) вступає не все підприємство, а окремі підрозділи)",
      title7: "Вимоги найманих працівників",
      title8:
        "Документ, що підтверджує факт надання висунутих найманими працівниками вимог роботодавцю",
      subtitle8:
        "(виписка ШКІ у разі надсилання рекомендованим повідомленням та скан листа, виписка з листа" +
        "електронної пошти з підтвердженням факту надсилання такого листа та доказом, що ця пошта" +
        "належить роботодавцю тощо)",
      title9:
        "Інші документи, які мають значення для вирішення колективного трудового спору (конфлікту)",
      subtitle9:
        "(колективні договори, галузеві угоди, інші документи щодо яких висуваються вимоги і які" +
        "мають значення для вирішення КТС (к).",
      title10:
        "Документи, в яких роботодавець або уповноважена ним особа, організація роботодавців," +
        "об'єднання організацій роботодавців надали відповідь найманим працівникам щодо можливості" +
        "задоволення висунутих вимог",
      title11: "Рівень спору",
      info1:
        "Наймані працівники підприємства, установи, організації або структурного, відокремленого" +
        "підрозділу або окремої категорії найманих працівників підприємства, установи," +
        "організації (повна назва) або профспілкової чи іншої уповноваженої найманими" +
        "працівниками організації (повна назва).",
      info2:
        "Повне найменування сторони роботодавця, уповноваженого приймати рішення щодо задоволення" +
        "висунутих найманими працівниками вимог в колективному трудовому спорі (конфлікті).",
      info3:
        " У цьому пункті зазначається керівник (роботодавець)/ліквідаційна комісія підприємства," +
        "установи, організації, якому було висунуто вимоги. У цьому пункті зазначається" +
        "уповноважена посадова особа (орган) до якої висунуто вимоги.",
      info4:
        "Для отримання відповідної інформації, рекомендується зробити запит до роботодавця. У " +
        "разі не надання роботодавцем зазначеної інформації, звертатися до Пенсійного Фонду" +
        "України, органів статистики, Державної служби статистики інших органів, що володіють" +
        "такою інформацією та уповноважені її надати.",
      info5:
        "Подається у разі, коли в КТС (к) вступає структурний, відокремлений підрозділ підприємства, окрема категорія найманих працівників.",
      info6: `Формування та затвердження вимог працівників або профспілки і визначення органу чи особи, які будуть представляти їх інтереси, загальними зборами або конференцією здійснюється наступним чином:
        <ul> 
        <li>збори скликаються з ініціативи виборного профспілкового органу чи іншого уповноваженого працівниками органу або ініціативної групи працівників, а також за пропозицією не менш як третини від загальної кількості штатних працівників.</li>
        <li>збори працівників є правоможними, якщо в них бере участь не менше половини працівників підприємства, установи, організації, окремого структурного підрозділу, окремої категорії працівників;</li>
        <li>конференції представників працівників можуть скликатися, у випадках, коли скликання зборів утруднене через багатозмінність або територіальну роз’єднаність цехів, відділів, дільниць та інших структурних підрозділів; </li>
        <li>конференція представників працівників є правоможною, якщо в ній бере участь не менше двох третин обраних представників працівників (делегатів конференції) або формуються шляхом збору підписів і вважаються чинними за наявності не менше половини підписів членів трудового колективу підприємства, установи, організації чи їх структурного підрозділу.</li>
        <li>у випадках, коли інтереси працівників представляє профспілка, об’єднання профспілок - рішенням виборного органу відповідної профспілки, об’єднання профспілок.</li>
        </ul>
        Рішення загальних зборів (конференції) найманих працівників приймаються відкритим або таємним голосуванням та вважаються чинними у разі, коли за них проголосувала така кількість учасників загальних зборів (конференції), яка складає за їх повноваженнями не менше ніж половини працюючих членів трудового колективу підприємства, установи, організації чи їх окремого структурного підрозділу.<br>
        Вимоги працівників, профспілки чи об’єднання профспілок оформляються протоколом (на слові протоколом має бути посилання на файл з протоколом, доступний для завантаження), який підписується головою і секретарем зборів (конференції) і надсилаються стороні роботодавця. До протоколу зборів (конференції) додаються матеріали реєстрації їх учасників (список працівників, які були присутніми на зборах, або список представників працівників та протокол (висновок) мандатної (лічильної) комісії).<br>
        В роботі зборів має право брати участь сторона роботодавця, представники місцевих органів виконавчої влади, органів місцевого самоврядування, працівники Національної служби посередництва і примирення та за згодою учасників даних загальних зборів (конференції) інші представники громадських об’єднань.<br>
        Затвердження вимог працівників та визначення органу чи особи, які будуть представляти їх інтереси може бути здійснено шляхом збору підписів (зразок підписного листа за посиланням).<br>
        Профспілковий орган чи ініціативна група працівників виготовляють підписні листи, в яких зазначаються вимоги, які пропонуються до висунення працівників та орган чи особа, які пропонуються для представлення інтересів працівників. Працівники розглядають пропозиції і добровільно ставлять свої підписи в підписному листі або накладають удосконалений/кваліфікований електронний підпис, у разі виготовлення підписних листів в електронному вигляді.<br>
        Після завершення збору підписів працівників результати збору підписів узагальнюються органом, уповноваженим представляти інтереси найманих працівників, про що складається протокол.<br>
        Вимоги працівників вважаються чинними за наявності не менше половини підписів членів трудового колективу підприємства, установи, організації чи їх структурного підрозділу або окремої категорії працівників.`,
      info7:
        "Форма для прикріплення файла (-ів) із документом, що підтверджує намір найманих працівників вступити в КТС(к) (протокол загальних зборів (конференції) найманих працівників з відповідними підписними листами, іншими документами, що підтверджують правоможність зборів, протокол засідання конференції представників (делегатів конференції) найманих працівників з відповідними підписними листами та документами, що підтверджують повноваження делегатів",
      info8: `Рекомендації щодо формування вимог:
      <ul>
      <li>Не допускається формування вимог у майбутньому часі, наприклад «Забезпечити стабільну виплату заробітної плати»;</li>
      <li>Не допускається загальний характер вимог, наприклад «Роботодавцю дотримуватись вимог Конституції України, Закону України «Про професійні спілки, їх права та гарантії діяльності», колективного договору від 23.08.2023 №49 між найманими працівниками і роботодавцем».</li>
      <li>У таких випадках слід зазначити статтю (-ті), їх частину(-и), абзац (-и) тощо, які порушуються або виписати порушення, яке можна ідентифікувати у законодавстві України, наприклад «Роботодавцю виконати пункт 5.8 Колективного договору від 23.08.2023 №49 між найманими працівниками і роботодавцем», «Виплатити заборгованість по заробітній платі за період з січня 2018 року по лютий 2019 року».</li>
      </ul>`,
      info9: `Вимоги працівників, профспілки чи об’єднання профспілок оформляються відповідним протоколом і надсилаються стороні роботодавця.
        <br>Важливо зафіксувати дату вручення вимог стороні роботодавця (в журналі або в інших реєстраційних формах обліку вхідної кореспонденції або в формі підпису сторони роботодавця на другому екземплярі вимог, що залишається в представника найманих працівників, або поштовим документом (квитанцією), що підтверджує відправку вимог рекомендованим листом, або на офіційну електронну адресу роботодавця). Незалежно від форми реєстрації, рекомендується запросити в роботодавця реєстраційний номер і дату.
        <br>З моменту отримання вимог Стороною роботодавця починається відлік строку розгляду вимог.
        <br>Днем одержання вимог вважається день їх реєстрації у журналі або книзі вхідної кореспонденції, яка ведеться відповідним структурним підрозділом на підприємстві, в установі, організації чи безпосередньо сторона спору, визначена Законом.
        <br>В процесі реєстрації вимог, що надійшли на розгляд стороні роботодавця, на протоколі, в якому вони зазначені, робиться відповідна помітка щодо дати реєстрації та вхідного номера.
        <br>У випадку надходження вимог поштою рекомендованим листом з повідомленням днем одержання вимог рахуватиметься день вручення Стороні роботодавця відповідного конверта (із вкладеними в нього вимогами), про що свідчитиме його особистий підпис або підпис працівника, який відповідає за діловодство, на поштовому повідомленні про вручення, індекс ШКІ або аналогічна позначка у сервісі трекінгу із зазначенням дати отримання. Записи у журналі вхідної документації в такому випадку здійснюються в день фактичного одержання поштового відправлення.
        <br>Згідно статті 5 Закону України «Про порядок вирішення колективних трудових спорів (конфліктів)» сторона роботодавця зобов’язана розглянути вимоги працівників, категорій працівників, колективу працівників чи профспілки та повідомити їх представників про своє рішення у триденний строк з дня одержання вимог (перебіг строку починається з наступного дня після дати одержання вимог).`,
      info10: `Сторони КТС (к):
      <ul>
      <li>на виробничому рівні - наймані працівники (окремі категорії найманих працівників) підприємства, установи, організації чи їх структурних підрозділів або первинна профспілкова чи інша уповноважена найманими працівниками організація та роботодавець. За дорученням інтереси роботодавця у колективному трудовому спорі (конфлікті) може представляти інша особа, організація роботодавців, об’єднання організацій роботодавців;</li>
      <li>на територіальному рівні - наймані працівники підприємств, установ, організацій однієї або декількох адміністративно-територіальних одиниць чи профспілки, їх об’єднання або інші уповноважені цими найманими працівниками органи та організації роботодавців, їх об’єднання або місцеві органи виконавчої влади, що діють на території відповідної адміністративно-територіальної одиниці;</li>
      <li>на галузевому рівні - наймані працівники підприємств, установ, організацій однієї або декількох галузей (професій) чи профспілки, їх об’єднання або інші уповноважені цими найманими працівниками органи та організації роботодавців, їх об’єднання або центральні органи виконавчої влади;</li>
      <li>на національному рівні - наймані працівники однієї або декількох галузей (професій) чи профспілки або їх об’єднання чи інші уповноважені найманими працівниками органи та всеукраїнські об’єднання організацій роботодавців або Кабінет Міністрів України.</li></ul>`,
      fileConditions:
        "Прикріпити документ можливо у наступних форматах: jpg, jpeg, png, docx, pdf.\n" +
        "Максимальний розмір файла - 5 мб",
      fileFormat: "мб",
      fileError: "Помилка при завантаженні файлу.",
      fileSizeError: "Розмір файлу перевищує",
      addFile: "Прикріпіть файл",
      formElements: {
        employees: "Наймані працівники",
        authorizedPersonToRepresentTheInterestsOfEmployees:
          "Особа, уповноважена представляти інтереси найманих працівників (прізвище, ім'я, по-батькові)",
        email: "Електронна пошта",
        phone: "Телефон",
        phoneIfAvailable: "Телефон (за наявності)",
        authorizedPersonToRepresentTheInterestsOfEmployer:
          "Особа, уповноважена представляти інтереси роботодавця (прізвище, ім'я, по-батькові)",
        employer: "Безпосередній роботодавець (прізвище, ім'я, по-батькові)",
        employerInfo:
          "Безпосередній роботодавець і власник або відповідний вищестоящий орган управління, який має право прийняти рішення співпадають",
        totalNumberOfEmployeesInNumbers: "Загальна кількість працівників цифрами",
        totalNumberOfEmployeesInWords: "Загальна кількість працівників словами",
        checkbox2:
          "Роботодавець не надав інформацію про організаційну структуру підприємства із зазначенням кількості окремої категорії найманих працівників, структурного, відокремленого підрозділу підприємства, наймані працівники якого вступають у колективний трудовий спір (конфлікт)",
        numberOfEmployeesInNumbers: "Кількість працівників цифрами",
        numberOfEmployeesInWords: "Кількість працівників словами",
        requirementsOfEmployees: "Вимоги найманих працівників",
        checkbox3: "Роботодавець не надав відповідь у передбачений законодавством строк",
      },
    },
    regions: {
      vi: "Вінницька",
      vo: "Волинська",
      dp: "Дніпропетровська",
      do: "Донецька",
      zh: "Житомирська",
      uz: "Закарпатська",
      zp: "Запорізька",
      if: "Івано-Франківська",
      ky: "Київська",
      ki: "Кіровоградська",
      lg: "Луганська",
      lv: "Львівська",
      mk: "Миколаївська",
      od: "Одеська",
      pl: "Полтавська",
      rv: "Рівненська",
      su: "Сумська",
      te: "Тернопільська",
      kh: "Харківська",
      ks: "Херсонська",
      km: "Хмельницька",
      ck: "Черкаська",
      cv: "Чернівецька",
      cn: "Чернігівська",
    },
    readMore: "Показати більше",
    readLess: "Показати менше",
    datepicker: {
      select: "Вибрати",
      cancel: "Скасувати",
    },
  },
};
