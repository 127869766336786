import { BASE_URL, Locale } from "@/constants";
import {
  JusticeActsResponse,
  LegislationListResponse,
  JudicialPracticeResponse,
  RegulatoryFilters,
  JudicialPracticeParams,
  LegislationListParams,
  JusticeActsParams,
} from "@/types";

export {
  JusticeActsResponse,
  LegislationListResponse,
  JudicialPracticeResponse,
  RegulatoryFilters,
  JudicialPracticeParams,
  LegislationListParams,
  JusticeActsParams,
};

const serializeParam = (param: string | number | Date | null | undefined) => {
  if (!param) return "";
  if (param instanceof Date) return param.toISOString();
  return String(param);
};

const serializeQueryParams = (params: {
  [key: string]: string | number | Date | null | undefined;
}) => {
  const res = new URLSearchParams();
  for (const [key, val] of Object.entries(params)) {
    if (!val) {
      continue;
    }
    if (Array.isArray(val)) {
      val.forEach((v) => res.append(key, serializeParam(v)));
      continue;
    }
    res.append(key, serializeParam(val));
  }
  return res.toString();
};

export default {
  async getJudicialPractice(
    locale: Locale,
    queryParams?: JudicialPracticeParams,
  ): Promise<JudicialPracticeResponse[]> {
    const response = await fetch(
      `${BASE_URL}judicialpractice?language=${locale}&${
        queryParams ? serializeQueryParams(queryParams) : ""
      }`,
      {
        method: "GET",
      },
    );
    if (response.status !== 200) {
      throw response.status;
    }

    return await response.json();
  },

  async getLegislationList(
    locale: Locale,
    queryParams?: LegislationListParams,
  ): Promise<LegislationListResponse[]> {
    const response = await fetch(
      `${BASE_URL}legislation?language=${locale}&${
        queryParams ? serializeQueryParams(queryParams) : ""
      }`,
      {
        method: "GET",
      },
    );
    if (response.status !== 200) {
      throw response.status;
    }

    return await response.json();
  },

  async getJusticeActs(
    locale: Locale,
    queryParams: JusticeActsParams,
  ): Promise<JusticeActsResponse[]> {
    const response = await fetch(
      `${BASE_URL}justiceacts?language=${locale}&${
        queryParams ? serializeQueryParams(queryParams) : ""
      }`,
      {
        method: "GET",
      },
    );
    if (response.status !== 200) {
      throw response.status;
    }

    return await response.json();
  },

  //   filter-values

  async getJusticeActsFilters(locale: Locale): Promise<RegulatoryFilters> {
    const response = await fetch(`${BASE_URL}justiceacts/filters?language=${locale}`, {
      method: "GET",
    });
    if (response.status !== 200) {
      throw response.status;
    }

    return await response.json();
  },

  async getLegislationFilters(locale: Locale): Promise<RegulatoryFilters> {
    const response = await fetch(`${BASE_URL}legislation/filters?language=${locale}`, {
      method: "GET",
    });
    if (response.status !== 200) {
      throw response.status;
    }

    return await response.json();
  },

  async getJudicialPracticeFilters(locale: Locale): Promise<RegulatoryFilters> {
    const response = await fetch(`${BASE_URL}judicialpractice/filters?language=${locale}`, {
      method: "GET",
    });
    if (response.status !== 200) {
      throw response.status;
    }

    return await response.json();
  },
};
